/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file
import './scss/frontend.scss';

import { Collapse } from 'bootstrap';

import Plyr from 'plyr';

document.querySelectorAll('[data-plyr-embed]').forEach((el) => {
  new Plyr(el);
});

import { Fancybox } from '@fancyapps/ui';
import '@fancyapps/ui/dist/fancybox/fancybox.css';
window.Fancybox = Fancybox;

import { Splide } from '@splidejs/splide';
import '@splidejs/splide/dist/css/splide.min.css';
window.Splide = Splide;
